<template>
  <v-container>
    <h1>Zach Kaarvik</h1>
  </v-container>
</template>

<script>
  export default {
    name: 'Main',

    data: () => ({
    }),
  }
</script>
